@font-face {
  font-family: "Northern";
  src: local("Northern"),
    url(./fonts/The-Northern-Block-Ltd-Typold-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Northern-medium";
  src: local("Northern-medium"),
    url(./fonts/The-Northern-Block-Ltd-Typold-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Northern";
  font-weight: 900;
  src: local("Northern"),
    url(./fonts/The-Northern-Block-Ltd-Typold-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Northern";
  font-weight: 255;
  src: local("Northern"),
    url(./fonts/The-Northern-Block-Ltd-Typold-Thin.otf) format("opentype");
}

body {
  margin: 0;
  font-family: Northern;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Northern;
}

h2,
button {
  font-family: Northern-medium;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none; /* Remove default bullets */
  color: #530644;
}

ul li::before {
  padding-top: 15px;
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ffd740; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space */
  margin-left: -1em; /* Also needed for space */
}
